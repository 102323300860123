import { useEffect, useState } from 'react';
import { AppContentCountQuery, useAppContentCountQuery } from '../graphql/dato/__generated__/dato-graphql.generated';

const useAppContentCount = (isVisible: boolean) => {
  const [appContentCount, setAppContentCount] = useState<AppContentCountQuery>();

  // Query for app content count when rending the component
  const [appContentCountResult] = useAppContentCountQuery({
    pause: !isVisible,
  });

  const { data: appContentCountData } = appContentCountResult;

  useEffect(() => {
    if (appContentCountData) setAppContentCount(appContentCountData);
  }, [appContentCountData]);

  return {
    appContentCount,
  };
};

export default useAppContentCount;
