import { useEffect, useState } from 'react';

import { useToursAndStoriesCountByCityIdQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { useCity } from '../contexts/CityContext';

const useToursAndStoriesCountByCityId = (isVisible: boolean) => {
  const { currentCity } = useCity();

  const [toursCount, setToursCount] = useState(0);
  const [storiesCount, setStoriesCount] = useState(0);

  const [result] = useToursAndStoriesCountByCityIdQuery({
    variables: { cityId: currentCity?.id },
    pause: !currentCity?.id || !isVisible,
  });

  useEffect(() => {
    const numbers = result.data?.city;
    if (numbers) {
      setToursCount(numbers._allReferencingToursMeta.count || 0);
      setStoriesCount(numbers._allReferencingStoriesMeta.count || 0)
    }
  }, [result.data]);

  return {
    toursCount: currentCity?.id ? toursCount : 0,
    storiesCount: currentCity?.id ? storiesCount : 0,
  };
};

export default useToursAndStoriesCountByCityId;
