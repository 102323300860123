import { useCallback, useEffect, useState } from 'react';

import { useCitiesWithToursAndStoriesCountQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { City } from '../interfaces/Interfaces';
import { useLocale } from '../contexts/LocaleContext';
import { uniqBy } from 'lodash-es';

const useCitiesForInfiniteScroll = (isVisible: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [citiesInQueryLocale, setCitiesInQueryLocale] = useState<{ [key: string]: City[]; }>({});
  const [infiniteScrollEventTarget, setInfiniteScrollEventTarget] = useState<any>(null);
  const [isAllCitiesReceivedInQueryLocale, setIsAllCitiesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  // Query for cities when rending the component
  const citiesPageSize = 100;
  const [citiesPageNumber, setCitiesPageNumber] = useState(0);

  const citiesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: citiesPageSize,
      skip: citiesPageNumber * citiesPageSize,
    };
  }, [queryLocale, citiesPageNumber]);

  const [citiesResult] = useCitiesWithToursAndStoriesCountQuery({
    variables: citiesQueryVariables(),
    pause: !isVisible,
    requestPolicy: 'network-only',
  });

  const { data: citiesData, fetching } = citiesResult;

  // Set cities when data is available
  useEffect(() => {
    if (citiesData?.cities) {
      // Add cities to the list
      const returnedCities = citiesData?.cities as City[];

      const newCitiesInQueryLocale = uniqBy(
        [...(citiesInQueryLocale?.[locale] || []), ...returnedCities],
        (city) => city.id
      );

      setCitiesInQueryLocale({
        ...citiesInQueryLocale,
        [locale]: newCitiesInQueryLocale,
      });

      if (returnedCities.length !== citiesPageSize) {
        setIsAllCitiesReceivedInQueryLocale({
          ...isAllCitiesReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [citiesData]
  );

  useEffect(() => {
    if (!fetching) {
      infiniteScrollEventTarget?.complete();
      setInfiniteScrollEventTarget(null);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetching]
  );

  useEffect(() => {
    setCitiesPageNumber(0);
    infiniteScrollEventTarget?.complete();
    setInfiniteScrollEventTarget(null);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryLocale]
  );

  return {
    cities: citiesInQueryLocale?.[queryLocale] || [],
    isAllCitiesReceived: isAllCitiesReceivedInQueryLocale[queryLocale],
    setCitiesPageNumber,
    setInfiniteScrollEventTarget,
  };
};

export default useCitiesForInfiniteScroll;
