import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy, filter, orderBy, map, isNumber, round } from 'lodash-es';

import SectionHeader from './SectionHeader';
import { useCitiesWithToursAndStoriesCountByCoordinatesQuery } from '../../../graphql/dato/__generated__/dato-graphql.generated';
import { City, CityWithDistanceToCurrentCity } from '../../../interfaces/Interfaces';
import { useLocale } from '../../../contexts/LocaleContext';
import Slider from '../../../components/sliders/Slider';
import CityWithContentCountCard from '../../../components/cards/CityWithContentCountCard';
import { getDistanceBetweenCoordinates } from '../../../helpers/turf-helpers';

const defaultQueryRadius = 50000; // 50km

const NearestCitiesWithContentSection: React.FC<{ city: City; isVisible: boolean }> = ({ city, isVisible }) => {
  const { queryLocale, locale } = useLocale();
  const { t } = useTranslation();

  const [queryRadius, setQueryRadius] = useState(defaultQueryRadius);
  const [citiesInQueryLocale, setCitiesInQueryLocale] = useState<{ [key: string]: CityWithDistanceToCurrentCity[] }>({});

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      latitude: city.location?.latitude,
      longitude: city.location?.longitude,
      radius: queryRadius
    };
  }, [queryLocale, queryRadius, city]);

  const [result] = useCitiesWithToursAndStoriesCountByCoordinatesQuery({
    variables: queryVariables(),
    pause: !city?.location?.latitude || !city?.location?.longitude || !isVisible,
  });

  const { data } = result;

  useEffect(() => {
    if (data?.cities) {
      const returnedCities = data?.cities as City[];
      // get only cities with content
      const filteredCities = filter(returnedCities, (city) =>
        !!(city?._allReferencingToursMeta?.count || city?._allReferencingStoriesMeta?.count)
      );
      // determine the distance to the current city
      const filteredCitiesWithDistanceToCurrentCity = map(filteredCities, (currCity) => {
        const distanceToCurrentCityInMeters = getDistanceBetweenCoordinates(city?.location, currCity?.location);
        const distanceToCurrentCity = isNumber(distanceToCurrentCityInMeters) ?
          round(distanceToCurrentCityInMeters / 1000, 1) :
          0;

        return { ...currCity, distanceToCurrentCity };
      });
      // sort by distance and number of content
      const sortedCities = orderBy(
        filteredCitiesWithDistanceToCurrentCity,
        ['distanceToCurrentCity', '_allReferencingToursMeta.count', '_allReferencingStoriesMeta.count'],
        ['asc', 'desc', 'desc'],
      );
      // set cities
      const newCitiesInQueryLocale = uniqBy(
        [...(citiesInQueryLocale?.[locale] || []), ...sortedCities],
        (city) => city.id
      );
      setCitiesInQueryLocale({
        ...citiesInQueryLocale,
        [locale]: newCitiesInQueryLocale,
      });

      if (
        newCitiesInQueryLocale?.length < 3 &&
        // send not more than 5 requests
        queryRadius < defaultQueryRadius * 3 * 3 * 3 * 3
      ) {
        // receive cities for by the bigger radius
        setQueryRadius(queryRadius * 3);
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  if (!citiesInQueryLocale?.[locale]?.length) return null;

  return (
    <div>
      <SectionHeader title={t('home.nearestCitiesWithContentSection.title')} className="px-4 mt-4 mb-2" />
      <Slider
        sliderProps={{
          // Enable virtual slides
          virtual: true,
          className: "px-2",

          // Default config (when window width is < 320px)
          slidesPerView: 1.5,

          // Responsive breakpoints
          breakpoints: {
            480: {
              slidesPerView: 2,
              spaceBetween: 6,
            }
          },
        }}
      >
        {citiesInQueryLocale?.[locale].map((city) => (<div key={city?.id} className="mx-2">
          <CityWithContentCountCard city={city}/>
        </div>))}
      </Slider>
    </div>
  );
};

export default NearestCitiesWithContentSection;
