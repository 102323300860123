import React from 'react';
import { IonCard, useIonRouter, } from '@ionic/react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useTranslation } from 'react-i18next';

import PlaceSearchImage from '../media/PlaceSearchImage';
import { LatLonField } from '../../graphql/dato/__generated__/dato-graphql.generated';
import { CityWithDistanceToCurrentCity } from '../../interfaces/Interfaces';
import { useCity } from '../../contexts/CityContext';
import { useLocale } from '../../contexts/LocaleContext';
import useRoutes from '../../hooks/useRoutes';
import useDefaultCityImage from '../../hooks/useDefaultCityImage';

const CityWithContentCountCard: React.FC<{
  city: CityWithDistanceToCurrentCity;
  navigationPath?: string;
}> = ({ city, navigationPath }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { setCurrentCityToStateAndLocalStorage } = useCity();
  const { localePath } = useRoutes();
  const { defaultCityImageUrl } = useDefaultCityImage();

  const responsiveImage = city?.preview?.[0]?.image?.responsiveImage as ResponsiveImageType;

  return (
    <IonCard
      key={city.id}
      className="relative m-0 p-0 cursor-pointer bg-transparent shadow-none"
      onClick={() => {
        setCurrentCityToStateAndLocalStorage(city);
        if (navigationPath) router.push(localePath(navigationPath));
      }}>
      <div
        className="relative aspect-square rounded-[6px] overflow-hidden bg-[#D9D9D9] shadow-[0px_4px_10px_-4px_rgba(14,0,54,0.28)]"
      >
        {!!responsiveImage ?
          <Image
            data={responsiveImage}
            layout="fill"
            objectFit="cover"
          />
          :
          <PlaceSearchImage
            searchText={city?.name as string}
            location={city?.location as LatLonField}
            maxWidth={400}
            className="absolute"
            defaultImageUrl={defaultCityImageUrl}
          />
        }
      </div>

      <div className="absolute bottom-4 left-1/2 -translate-x-1/2 w-full px-2 text-center text-white">
        <p className="text-[1rem] font-medium">{city.name}</p>
        <p className="text-[0.75rem] font-light">
          {!!city._allReferencingToursMeta?.count && t('cityWithContentCountCard.toursCount', {
            toursCount: new Intl.NumberFormat(locale).format(city._allReferencingToursMeta?.count),
            count: city._allReferencingToursMeta?.count,
          })}
          {!!city._allReferencingToursMeta?.count && !!city._allReferencingStoriesMeta?.count && ', '}
          {!!city._allReferencingStoriesMeta?.count && t('cityWithContentCountCard.storiesCount', {
            storiesCount: new Intl.NumberFormat(locale).format(city._allReferencingStoriesMeta?.count),
            count: city._allReferencingStoriesMeta?.count,
          })}
        </p>
        {!!city.distanceToCurrentCity && <p className="pt-2">
          {t('cityWithContentCountCard.distance', { distance: city.distanceToCurrentCity })}
        </p>}
      </div>
    </IonCard>
  );
};

export default CityWithContentCountCard;
