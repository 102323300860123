import { IonCard, IonCardHeader, IonIcon } from '@ionic/react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import { Tour } from '../../interfaces/Interfaces';
import { ItemStatus } from '../../graphql/dato/__generated__/dato-graphql.generated';
import CategoryTags from '../category-tags/CategoryTags';
import StarRating from '../StarRating';
import {
  TourRatingStatistics,
  UserTourAudioStatistics
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import ProgressBar from '../ProgressBar';
import useAuthStore from '../../stores/useAuthStore';
import accessTimeIcon from '../../assets/tour/access-time.svg';
import headsetIcon from '../../assets/tour/headset.svg';
import swapCallsIcon from '../../assets/tour/swap-calls.svg';
import { useLocale } from '../../contexts/LocaleContext';
import { getTourAudioDurationInMinutes, isFreeTour } from '../../helpers/tour-helpers';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const TourCard: React.FC<{
  tour: Tour;
  tourRatingStatistics?: TourRatingStatistics;
  userTourAudioStatistics?: UserTourAudioStatistics;
}> = ({ tour, tourRatingStatistics, userTourAudioStatistics }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  const isDraftTour = tour._status === ItemStatus.draft;

  return (
    <IonCard
      routerLink={tour.slug ? generatePath("/:locale(de|en|fr|es)/tours/:tourSlug", {
        locale,
        tourSlug: tour.slug,
      }) : undefined}
      routerDirection="forward"
      className="m-1 rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.TOUR_CARD_CLICKING, {
            tourId: tour?.id,
            tourTitle: tour?.title,
          });
        }
      }}
    >
      {tourHighlightImage && (
        <div className="relative aspect-[4/3]"><Image data={tourHighlightImage} layout="fill" objectFit="cover" /></div>
      )}

      {isDraftTour && (
        <div className="absolute top-0 right-0 mx-3 my-3">
          <div className="rounded-sm bg-slate-200/[0.85] px-2 py-0.5 text-sm font-medium text-slate-800 shadow">
            {t("dictionary.preview")}
          </div>
        </div>
      )}

      <IonCardHeader className="flex min-h-[7.25rem] flex-col justify-between bg-white px-4 pb-4 pt-3.5">
        {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
        <CategoryTags
          categories={tour.categories}
          wrapperClassName="my-1 line-clamp-2 min-h-[54px]"
        />

        <div className="text-[1.25rem] text-[#232437] font-bold leading-[1.5rem] line-clamp-2 mb-1 min-h-[48px]">
          {tour.title}
        </div>

        {tour.creatorProfile && <div className="mr-1">
          <span className="text-[1rem] text-[#535E69] font-medium">{tour.creatorProfile.creatorName}</span>
        </div>}

        <div className="min-h-[21px]">
          {tourRatingStatistics && <StarRating tourRatingStatistics={tourRatingStatistics} />}
        </div>

        {isAuthenticated && <div className="py-2"><ProgressBar progress={userTourAudioStatistics?.audioProgress} /></div>}

        <div className="grow" />

        <div className="border-b border-[D5D6DC] opacity-40 mb-4 mt-1.5" />

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex">
            {getTourAudioDurationInMinutes(tour) && (
              <div className="flex flex-col items-center mr-3">
                <IonIcon src={headsetIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {getTourAudioDurationInMinutes(tour)} {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}

            {tour.distanceInKilometers && (
              <div className="flex flex-col items-center mr-3">
                <IonIcon icon={swapCallsIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {tour.distanceInKilometers} {t("tour.kilometersInShortForm")}
                </div>
              </div>
            )}

            {tour.durationInMinutes && (
              <div className="flex flex-col items-center">
                <IonIcon src={accessTimeIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {tour.durationInMinutes} {t("tour.minutesInShortForm")}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center text-[0.875rem] font-bold">
            {isFreeTour(tour) ?
              <div className="text-[#3DAB91] p-2 rounded-[5px] bg-[#EAFFFA]">{t('tourCard.free')}</div> :
              <div className=" text-[#976808] p-2 rounded-[5px] bg-[#9768084a]">{t('tourCard.premium')}</div>}
          </div>
        </div>
      </IonCardHeader>
    </IonCard>
  );
};

export default TourCard;
