import { useEffect, useMemo, useState } from 'react';
import {
  IonContent,
  useIonViewDidEnter
} from '@ionic/react';
import { filter, find, isEqual, map } from 'lodash-es';
import clsx from 'clsx';
import { useCustomCompareEffect } from 'react-use';

import AppHeader from '../components/AppHeader';
import AppLayout from '../layouts/AppLayout';
import { useLocale } from '../contexts/LocaleContext';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import useAuthStore from '../stores/useAuthStore';
import useIonVisible from '../hooks/useIonVisible';
import ToursFilter from '../components/filters/ToursFilter';
import { getItemFromStorage, setItemToStorage } from '../helpers/storage-helpers';
import useCategories from '../hooks/useCategories';
import { checkIfTourSelectedByCategory } from '../helpers/tour-helpers';
import FloatingToursMapButton from '../components/buttons/FloatingToursMapButton';
import { useCity } from '../contexts/CityContext';
import CitiesListSectionWithInfiniteScroll from '../components/CitiesListSectionWithInfiniteScroll';
import CreateTourSection from './ToursPage/CreateTourSection';
import FullWidthTourCard from '../components/cards/FullWidthTourCard';
import useToursRatingStatistics from '../hooks/useToursRatingStatistics';
import useUserToursAudioStatistics from '../hooks/useUserToursAudioStatistics';
import ToursLanguageSwitcher from '../components/ToursLanguageSwitcher';
import useStoryExplorationStore from '../stores/useStoryExplorationStore';
import useCitiesWithToursByCoordinates from '../hooks/useCitiesWithToursByCoordinates';
import useCitiesWithToursNumberForLocales from '../hooks/useCitiesWithToursNumberForLocales';
import useToursAndStoriesCountByCityId from '../hooks/useToursAndStoriesCountByCityId';

const ToursPage: React.FC = () => {
  const { locale } = useLocale();
  const { isVisible } = useIonVisible();
  const { tours, isLoading } = useCitiesWithToursByCoordinates(isVisible);
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { categoriesInQueryLocale, isAllCategoriesReceivedInQueryLocale } = useCategories(isVisible);
  const { currentCity } = useCity();
  const { toursNumberForLocales } = useCitiesWithToursNumberForLocales(isVisible);
  const { toursCount } = useToursAndStoriesCountByCityId(isVisible);

  const [selectedCategoriesInQueryLocale, setSelectedCategoriesInQueryLocale] = useState<{
    [key: string]: string[]
  }>({});

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isExploreModeEnabled = useStoryExplorationStore((state) => state.isExploreModeEnabled);

  const { toursRatingStatistics} = useToursRatingStatistics(map(tours, ({ id }) => id), isVisible);
  const { userToursAudioStatistics } = useUserToursAudioStatistics(map(tours, ({ id }) => id), isAuthenticated, isVisible);

  const filteredTours = useMemo(() => {
    return filter(tours, (tour) => checkIfTourSelectedByCategory(
      tour,
      selectedCategoriesInQueryLocale[locale],
      categoriesInQueryLocale[locale])
    );
  }, [tours, selectedCategoriesInQueryLocale, categoriesInQueryLocale, locale]);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_LIST_OF_TOURS);
    }
  });

  useEffect(() => {
    const getSelectedCategoriesFromStorageAndSet = async () => {
      const previouslySelectedCategories = await getItemFromStorage('selectedCategoriesForTours');

      if (!previouslySelectedCategories?.[locale]) {
        // set all categories as chosen for the current locale for the first time
        setSelectedCategoriesInQueryLocale({
          ...(previouslySelectedCategories || {}),
          [locale]: map(categoriesInQueryLocale[locale], category => category.id)
        });
      } else {
        // set previously selected categories from storage
        setSelectedCategoriesInQueryLocale(previouslySelectedCategories);
      }
    };

    if (isAllCategoriesReceivedInQueryLocale[locale]) {
      getSelectedCategoriesFromStorageAndSet();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllCategoriesReceivedInQueryLocale]
  );

  const updateSelectedCategories = async (categories: string[]) => {
    const selectedCategoriesForLocales = {
      ...selectedCategoriesInQueryLocale,
      [locale]: categories,
    };
    setSelectedCategoriesInQueryLocale(selectedCategoriesForLocales);
    await setItemToStorage('selectedCategoriesForTours', selectedCategoriesForLocales);
  };

  const [citySearchAdditionalButton, setCitySearchAdditionalButton] = useState<JSX.Element>();

  useCustomCompareEffect(() => {
    isVisible && setCitySearchAdditionalButton && setCitySearchAdditionalButton(<ToursFilter
      tours={tours}
      categories={categoriesInQueryLocale[locale]}
      previouslySelectedCategoryIds={selectedCategoriesInQueryLocale[locale]}
      setSelectedCategories={updateSelectedCategories}
    />)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tours, categoriesInQueryLocale?.[locale], selectedCategoriesInQueryLocale?.[locale], isVisible],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return (
    <AppLayout isLoaderShown={isVisible && isLoading}>
      <AppHeader citySearchAdditionalButton={citySearchAdditionalButton}/>

      <IonContent>
        {!!currentCity?.id && !!toursCount && <ToursLanguageSwitcher
          toursNumberForLocales={toursNumberForLocales}
          isVisible={isVisible}
          wrapperClassName={clsx("mx-auto mb-3", isExploreModeEnabled ? "pt-2.5" : "")}
        />}

        {!!currentCity?.id && !!tours?.length && <div className="relative mx-auto max-w-xl mb-[70px]">
          {filteredTours?.map((tour, i) => <div key={tour.id}>
            <FullWidthTourCard
              tour={tour}
              tourRatingStatistics={find(toursRatingStatistics, ['datoTourId', tour.id])}
              userTourAudioStatistics={find(userToursAudioStatistics, ['datoTourId', tour.id])}
            />
            {/* display section of tour creation after 4th tour card or after the last card if there are fewer than 4 tours */}
            {/* TODO: delete this condition when the GPT functionality is ready */}
            {process.env.REACT_APP_DISPLAY_CREATE_TOUR_BUTTON === 'true' &&
              (i === 3 || (filteredTours?.length < 4 && filteredTours?.length === i + 1)) && <CreateTourSection/>}
          </div>)}

          <FloatingToursMapButton />
        </div>}

        {(!currentCity || !toursCount) && <div className="mx-auto min-h-full max-w-xl">
          <CitiesListSectionWithInfiniteScroll isVisible={isVisible} />
        </div>}
      </IonContent>
    </AppLayout>
  );
};

export default ToursPage;
