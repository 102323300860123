import React, { useMemo } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { filter } from 'lodash-es';

import { useLocale } from '../contexts/LocaleContext';
import CityWithContentCountCard from './cards/CityWithContentCountCard';
import useCitiesForInfiniteScroll from '../hooks/useCitiesForInfiniteScroll';
import { useGetNumberOfCitiesWithContentQuery } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { useCity } from '../contexts/CityContext';
import { CityWithDistanceToCurrentCity } from '../interfaces/Interfaces';

const CitiesListSectionWithInfiniteScroll: React.FC<{ isVisible: boolean; addSafeAreaTop?: boolean }> = ({ isVisible, addSafeAreaTop }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { currentCity } = useCity();

  const { cities, isAllCitiesReceived, setCitiesPageNumber, setInfiniteScrollEventTarget } = useCitiesForInfiniteScroll(isVisible);
  const { data: numberOfCitiesData } = useGetNumberOfCitiesWithContentQuery({
    fetchPolicy: 'no-cache',
  });

  const filteredCities = useMemo(() => {
    return filter(cities, (city) =>
      !!city._allReferencingStoriesMeta?.count || !!city._allReferencingToursMeta?.count
    );
  }, [cities]);

  return (
    <div className="w-full p-5 text-center">
      <div className="text-[1rem] text-[#232437] font-semibold">
        {t(!currentCity ? 'tours.selectCitySection.enterCityInSearchField' : 'tours.selectCitySection.noResults.title')}
      </div>
      {!!currentCity && <div className="text-[0.75rem] text-[#C4C4C4] font-semibold pt-2">
        {t('tours.selectCitySection.noResults.text', { cityName: currentCity.name })}
      </div>}

      <div className="flex gap-4 items-center text-[1rem] text-[#D6D6D6] font-bold my-4">
        {!currentCity && (<>
          <div className="flex-1 border-t-[1px] border-[#D6D6D6]"></div>
          <div>{t('dictionary.or')}</div>
        </>)}
        <div className="flex-1 border-t-[1px] border-[#D6D6D6]"/>
      </div>

      <div className="text-[1rem] text-[#232437] font-semibold mb-5">
        {t('tours.selectCitySection.chooseOneOfCities', {
          citiesCount: new Intl.NumberFormat(locale).format(numberOfCitiesData?.dato?.getNumberOfCitiesWithContent || 0)
        })}
      </div>

      <div className="ion-content-scroll-host grid grid-cols-2 gap-4">
        {filteredCities?.map((city) => (
          <CityWithContentCountCard key={city.id} city={city as CityWithDistanceToCurrentCity}/>
        ))}
      </div>
      {!isAllCitiesReceived && <IonInfiniteScroll
        onIonInfinite={(ev) => {
          // By setting the page size another GraphQL query for the next page gets executed
          setCitiesPageNumber((citiesPageNumber) => citiesPageNumber + 1);
          setInfiniteScrollEventTarget(ev.target);
        }}
      >
        <IonInfiniteScrollContent loadingSpinner="bubbles" />
      </IonInfiniteScroll>}
    </div>
  );
};

export default CitiesListSectionWithInfiniteScroll;
