import { Image, ResponsiveImageType } from 'react-datocms';
import { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import {
  useApplicationQuery,
  usePopularCitiesQuery
} from '../../../../graphql/dato/__generated__/dato-graphql.generated';
import { useLocale } from '../../../../contexts/LocaleContext';
import Slider from '../../../../components/sliders/Slider';
import CityCard from './CityCard';
import { Application, City } from '../../../../interfaces/Interfaces';
import useRoutes from '../../../../hooks/useRoutes';

const GeneralOverviewSection: React.FC<{ isVisible: boolean; }> = ({ isVisible }) => {
  const { queryLocale } = useLocale();
  const { localePath } = useRoutes();
  const { t } = useTranslation();

  const [applicationSettings, setApplicationSettings] = useState<Application>();
  const [popularCities, setPopularCities] = useState<City[]>([]);

  const [applicationResult] = useApplicationQuery({
    variables: {
      locale: queryLocale
    },
    pause: !isVisible,
  });

  const [citiesResult] = usePopularCitiesQuery({
    variables: {
      locale: queryLocale,
      skip: 0,
      first: 100,
    },
    pause: !isVisible,
  });

  useEffect(() => {
    if (applicationResult?.data?.application) {
      setApplicationSettings(applicationResult?.data?.application as Application);
    }
  }, [applicationResult?.data]);

  useEffect(() => {
    if (citiesResult?.data?.cities) {
      setPopularCities(citiesResult?.data?.cities as City[]);
    }
  }, [citiesResult?.data]);

  const responsiveImage = applicationSettings?.homePageImage?.responsiveImage as ResponsiveImageType;

  return (
    <>
      {responsiveImage && <div className="relative min-h-[600px] h-fit">
        <Image data={responsiveImage} layout="fill" objectFit="cover"/>

        <div style={{
          background: 'linear-gradient(8deg, rgba(19, 35, 95, 0.95) 46.59%, rgba(227, 222, 255, 0.22) 77.52%)'
        }} className="absolute top-0 right-0 bottom-0 left-0"/>

        <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-end pb-2 pt-[80px]">
          <h3 className="text-[2rem] text-white font-bold leading-10 px-7 mb-1">{applicationSettings?.homePageTitle}</h3>
          <div className="flex justify-between items-center mb-5">
            <p className="text-[1rem] text-[#D6D5DC] px-7">{applicationSettings?.homePageText}</p>

            <IonButton
              fill="clear"
              className="normal-case text-primary tracking-normal"
              routerLink={localePath(`cities`)}
              routerDirection="forward"
            >
              <span className="font-semibold">{t('home.generalOverviewSection.buttons.showMore')}</span>
            </IonButton>
          </div>

          <Slider
            sliderProps={{
              // Enable virtual slides
              virtual: true,
              className: "px-2",

              // Default config (when window width is < 320px)
              slidesPerView: 1.5,
              // spaceBetween: 6,

              // Responsive breakpoints
              breakpoints: {
                480: {
                  slidesPerView: 2,
                  spaceBetween: 6,
                }
              },
            }}
          >
            {popularCities.map((city) => <CityCard
              key={city?.id}
              city={city}
            />)}
          </Slider>
        </div>
      </div>}
    </>
  );
};

export default GeneralOverviewSection;
