import { useTranslation } from 'react-i18next';
import { IonCard, IonCardHeader, IonIcon } from '@ionic/react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { locationSharp } from 'ionicons/icons';

import { TourWithCityDataForTourCard } from '../../interfaces/Interfaces';
import {
  TourRatingStatistics,
  UserTourAudioStatistics
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useRoutes from '../../hooks/useRoutes';
import StarRating from '../StarRating';
import useAuthStore from '../../stores/useAuthStore';
import ProgressBar from '../ProgressBar';
import { ItemStatus } from '../../graphql/dato/__generated__/dato-graphql.generated';
import CategoryTags from '../category-tags/CategoryTags';
import headsetIcon from '../../assets/tour/headset.svg';
import swapCallsIcon from '../../assets/tour/swap-calls.svg';
import accessTimeIcon from '../../assets/tour/access-time.svg';
import { getTourAudioDurationInMinutes, isFreeTour } from '../../helpers/tour-helpers';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import { useCity } from '../../contexts/CityContext';
import IconsOfAvailableTourLocales from './IconsOfAvailableTourLocales';

const FullWidthTourCard: React.FC<{
  tour: TourWithCityDataForTourCard;
  tourRatingStatistics?: TourRatingStatistics;
  userTourAudioStatistics?: UserTourAudioStatistics;
}> = ({ tour, tourRatingStatistics, userTourAudioStatistics }) => {
  const { t } = useTranslation();
  const { tourPath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentCity } = useCity();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage?.responsiveImage as ResponsiveImageType;

  const isDraftTour = tour._status === ItemStatus.draft;

  return (
    <IonCard
      routerLink={tourPath(tour)}
      routerDirection="forward"
      className="m-0 rounded-[0px] shadow-none mb-2.5"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.TOUR_CARD_CLICKING, {
            tourId: tour?.id,
            tourTitle: tour?.title,
          });
        }
      }}
    >
      {isDraftTour && (
        <div className="absolute top-0 right-0 mx-3 my-3">
          <div className="rounded-sm bg-slate-200/[0.85] px-2 py-0.5 text-sm font-medium text-slate-800 shadow">
            {t('dictionary.preview')}
          </div>
        </div>
      )}

      <IonCardHeader className="bg-white px-4 py-5">
        <div className="flex justify-between gap-5">
          <div className="flex flex-col flex-1">
            {tourRatingStatistics && <div className="mb-1.5"><StarRating
              tourRatingStatistics={tourRatingStatistics}
              starDimension="16px"
              captionClassName="text-black text-[0.75rem] font-semibold ml-1"
            /></div>}

            <div className="text-[1rem] text-[#232437] font-bold leading-[1.5rem] line-clamp-2">{tour.title}</div>

            {tour.creatorProfile && <div>
                <span className="text-[0.75rem] text-[#535E69] font-medium">{tour.creatorProfile.creatorName}</span>
            </div>}

            {isAuthenticated && <div className="py-1">
                <ProgressBar progress={userTourAudioStatistics?.audioProgress}/>
            </div>}

            {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
            <CategoryTags
              categories={tour.categories}
              wrapperClassName="my-2 line-clamp-2 min-h-[54px]"
            />
          </div>

          {tourHighlightImage && (
            <div className="relative aspect-square h-[100px]">
              <Image data={tourHighlightImage} layout="fill" objectFit="cover"/>
            </div>
          )}
        </div>

        <div className="flex mb-2">
          <IconsOfAvailableTourLocales tour={tour} />
          {!!tour?.city && <div className="flex text-[0.75rem] text-[#687582] font-semibold ml-2.5">
            <div className="border-r-[2px] border-[#687582] mr-1.5"/>
            <IonIcon
              icon={locationSharp}
              className="min-w-[20px] h-[18px] text-[#687582] mr-0.5"
            />
            <span dangerouslySetInnerHTML={{ __html: t('tourCard.distanceToCurrentCity', {
              tourCityName: tour.city.name,
              distanceToCurrentCity: tour.city.distanceToCurrentCity,
              currentCityName: currentCity?.name
            }) as string }} />
          </div>}
        </div>

        <div className="flex justify-between">
          <div className="flex gap-2.5">
            {getTourAudioDurationInMinutes(tour) && (
              <div className="flex items-center">
                <IonIcon src={headsetIcon} className="mr-0.5 w-[20px] h-[20px]"/>
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {getTourAudioDurationInMinutes(tour)} {t('tour.minutesInShortForm')}
                </div>
              </div>
            )}

            {tour.distanceInKilometers && (
              <div className="flex items-center">
                <IonIcon icon={swapCallsIcon} className="mr-0.5 w-[20px] h-[20px]"/>
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {tour.distanceInKilometers} {t('tour.kilometersInShortForm')}
                </div>
              </div>
            )}

            {tour.durationInMinutes && (
              <div className="flex items-center">
                <IonIcon src={accessTimeIcon} className="mr-0.5 w-[20px] h-[20px]"/>
                <div className="whitespace-nowrap text-[0.75rem] text-[#687582] font-semibold">
                  {tour.durationInMinutes} {t('tour.minutesInShortForm')}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center text-[0.875rem] font-bold">
            {isFreeTour(tour) ?
              <div className="text-[#3DAB91] p-2 rounded-[5px] bg-[#EAFFFA]">{t('tourCard.free')}</div> :
              <div className="text-[#976808] p-2 rounded-[5px] bg-[#9768084a]">{t('tourCard.premium')}</div>}
          </div>
        </div>
      </IonCardHeader>
    </IonCard>
  );
};

export default FullWidthTourCard;
