import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { includes, values } from 'lodash-es';
import * as H from 'history';

import { SiteLocale } from '../graphql/dato/__generated__/dato-graphql.generated';

export enum Locales {
  DE = "de",
  EN = "en",
  FR = "fr",
  ES = "es",
}

const DefaultLocale = Locales.DE;

const useValue = () => {
  const { i18n } = useTranslation();

  const [location, setLocation] = useState<H.Location<any>>();

  // Currently resolved locale or fallback locale
  const locale = (i18n.resolvedLanguage ?? DefaultLocale) as Locales;

  // Locale to be used in GraphQL queries to DatoCMS backend
  const queryLocale = locale as unknown as SiteLocale;

  const [tourQueryLocale, setTourQueryLocale] = useState<SiteLocale>(queryLocale);

  // set tour query locale
  useEffect(() => {
    if (!location) return;

    const params = new URLSearchParams(location?.search);
    const tourQueryLocaleParam = params.get('tourQueryLocale') || '';

    // Locale to be used in GraphQL queries to DatoCMS backend for the tour functionality
    const tourQueryLocale = includes(values(Locales), tourQueryLocaleParam) ?
      tourQueryLocaleParam as unknown as SiteLocale :
      queryLocale;

    setTourQueryLocale(tourQueryLocale);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location?.search, queryLocale]
  );

  return {
    locale, // Currently resolved locale
    queryLocale, // Locale to be used in GraphQL queries to DatoCMS backend
    tourQueryLocale, // Locale to be used in GraphQL queries to DatoCMS backend for the tour functionality
    locales: Locales, // All supported locales
    defaultLocale: DefaultLocale, // Default locale
    setLocation, // set the location from the child component which has access to it
  };
};

export const LocaleContext = createContext({} as ReturnType<typeof useValue>);

const LocaleProvider: React.FC = ({ children }) => {
  return (
    <LocaleContext.Provider value={useValue()}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => {
  return useContext(LocaleContext);
};

export { LocaleProvider, useLocale };
