import { IonButton, IonCard, IonContent, IonIcon, useIonViewDidEnter } from '@ionic/react';
import { useDebouncedEffect } from '@react-hookz/web';
import Map, { MapRef } from 'react-map-gl';
import { memo, TouchEventHandler, useEffect, useRef, useState } from 'react';
import { useSwiperSlide } from 'swiper/react';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { find, findIndex, inRange } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Image, ResponsiveImageType } from 'react-datocms';
import { arrowForwardOutline, locationSharp } from 'ionicons/icons';

import AppLayout from '../layouts/AppLayout';
import useGeolocationPermissions from '../hooks/useGeolocationPermissions';
import AppHeader from '../components/AppHeader';
import useIonVisible from '../hooks/useIonVisible';
import TourRoute from '../components/map/TourRoute';
import Slider from '../components/sliders/Slider';
import { TourWithCityDataForTourCard } from '../interfaces/Interfaces';
import { ItemStatus } from '../graphql/dato/__generated__/dato-graphql.generated';
import CategoryTags from '../components/category-tags/CategoryTags';
import headsetIcon from '../assets/tour/headset.svg';
import swapCallsIcon from '../assets/tour/swap-calls.svg';
import accessTimeIcon from '../assets/tour/access-time.svg';
import useRoutes from '../hooks/useRoutes';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import { useCity } from '../contexts/CityContext';
import useKeyboard from '../hooks/useKeyboard';
import GeolocateControl from '../components/map/GeolocateControl';
import { getTourAudioDurationInMinutes, isFreeTour } from '../helpers/tour-helpers';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import { useLocale } from '../contexts/LocaleContext';
import { setMapLanguage } from '../helpers/map-helpers';
import ToursLanguageSwitcher from '../components/ToursLanguageSwitcher';
import useStoryExplorationStore from '../stores/useStoryExplorationStore';
import useCitiesWithToursByCoordinates from '../hooks/useCitiesWithToursByCoordinates';
import IconsOfAvailableTourLocales from '../components/cards/IconsOfAvailableTourLocales';
import useCitiesWithToursNumberForLocales from '../hooks/useCitiesWithToursNumberForLocales';

const TourCard: React.FC<{
  tour: TourWithCityDataForTourCard;
  setActiveTourId: (activeTourId: string) => void;
  handleTouchStart: TouchEventHandler<HTMLDivElement>;
  handleTouchMove: TouchEventHandler<HTMLDivElement>;
  handleTouchEnd: TouchEventHandler<HTMLDivElement>;
}> = memo(({ tour, setActiveTourId, handleTouchStart, handleTouchMove, handleTouchEnd }) => {
  const { t } = useTranslation();
  const { tourPath } = useRoutes();
  const swiperSlide = useSwiperSlide();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentCity } = useCity();

  useEffect(() => {
    if (swiperSlide.isActive) setActiveTourId(tour.id);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [swiperSlide.isActive]
  );

  const tourHighlightImage = tour.highlightImage?.responsiveImage as ResponsiveImageType;

  const isDraftTour = tour._status === ItemStatus.draft;

  return (
    <IonCard
      className="m-1 rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
      routerLink={tourPath(tour)}
      routerDirection="forward"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.TOUR_CARD_CLICKING, {
            tourId: tour?.id,
            tourTitle: tour?.title,
          });
        }
      }}
    >
      <div className="flex min-h-[7.25rem] flex-col justify-between bg-white px-4 pb-0">
        <div
          className="h-9 flex justify-center items-center"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="w-[60px] h-[4px] bg-[#d5d6dc]"/>
        </div>
        <div className="flex justify-between gap-1.5">
          <div>
            <div className="text-[1rem] text-[#232437] font-semibold leading-[1.4rem] line-clamp-2 mb-2 min-h-[45px]">
              {tour.title}
            </div>

            {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
            <CategoryTags
              categories={tour.categories}
              wrapperClassName="my-1 line-clamp-2 min-h-[54px]"
            />
          </div>

          {tourHighlightImage && (
            <div className="relative aspect-square w-[75px] min-w-[75px] h-[75px] rounded-[6px] overflow-hidden">
              <Image data={tourHighlightImage} layout="fill" objectFit="cover" />
            </div>
          )}
        </div>

        {isDraftTour && (
          <div className="absolute top-0 right-0 mx-3 my-3">
            <div className="rounded-sm bg-slate-200/[0.85] px-2 py-0.5 text-sm font-medium text-slate-800 shadow">
              {t('dictionary.preview')}
            </div>
          </div>
        )}

        <div className="flex mb-2">
          <IconsOfAvailableTourLocales tour={tour} />

          {!!tour?.city && <div className="flex items-center text-[0.75rem] text-[#687582] font-semibold ml-2.5">
              <div className="h-full border-r-[2px] border-[#687582] mr-1.5" />
              <IonIcon
                icon={locationSharp}
                className="min-w-[18px] h-[16px] text-[#687582] mr-1"
              />
              <div dangerouslySetInnerHTML={{
                __html: t('tourCard.distanceToCurrentCity', {
                  tourCityName: tour.city.name,
                  distanceToCurrentCity: tour.city.distanceToCurrentCity,
                  currentCityName: currentCity?.name
                }) as string
              }} />
          </div>}
        </div>

        <div className="border-b border-[D5D6DC] opacity-40 mb-2 mt-1.5" />

        <div className="flex flex-wrap gap-2 justify-between">
          <div className="flex">
            {getTourAudioDurationInMinutes(tour) && (
              <div className="flex flex-col items-center mr-4">
                <IonIcon src={headsetIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.6875rem] text-[#687582] font-semibold">
                  {getTourAudioDurationInMinutes(tour)} {t('tour.minutesInShortForm')}
                </div>
              </div>
            )}

            {tour.distanceInKilometers && (
              <div className="flex flex-col items-center mr-4">
                <IonIcon icon={swapCallsIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.6875rem] text-[#687582] font-semibold">
                  {tour.distanceInKilometers} {t('tour.kilometersInShortForm')}
                </div>
              </div>
            )}

            {tour.durationInMinutes && (
              <div className="flex flex-col items-center">
                <IonIcon src={accessTimeIcon} className="mb-0.5 w-[20px] h-[20px]" />
                <div className="whitespace-nowrap text-[0.6875rem] text-[#687582] font-semibold">
                  {tour.durationInMinutes} {t('tour.minutesInShortForm')}
                </div>
              </div>
            )}
          </div>

          <div className="flex items-center text-[0.875rem] font-bold">
            {isFreeTour(tour) ?
              <div className="text-[#3DAB91] p-2 rounded-[5px] bg-[#EAFFFA]">{t('tourCard.free')}</div> :
              <div className=" text-[#976808] p-2 rounded-[5px] bg-[#9768084a]">{t('tourCard.premium')}</div>}
          </div>
        </div>

        <div className="flex justify-end">
          <IonButton
            fill="clear"
            className="normal-case font-bold text-[0.75rem] tracking-normal mr-[-8px]"
          ><IonIcon icon={arrowForwardOutline} slot="end"/>{t('tourCard.buttons.viewDetails')}</IonButton>
        </div>
      </div>
    </IonCard>
  );
});

const ToursMapPage: React.FC = () => {
  // Request geolocation permissions if not granted yet
  // by triggering a Geolocation request
  useGeolocationPermissions();

  const { locale } = useLocale();
  const { isVisible } = useIonVisible();
  const { tours } = useCitiesWithToursByCoordinates(isVisible);
  const { currentCity } = useCity();
  const { isKeyboardOpen, wasKeyboardOpen } = useKeyboard();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { toursNumberForLocales } = useCitiesWithToursNumberForLocales(isVisible);

  const [activeTourId, setActiveTourId] = useState<string | null>();
  const [activeTourIndex, setActiveTourIndex] = useState<number | null>();
  const [isSliderOpened, setIsSliderOpened] = useState<boolean>();
  const [sliderTouchStartY, setSliderTouchStartY] = useState<number>(0);
  const [sliderTranslateY, setSliderTranslateY] = useState<number>(0);

  const isExploreModeEnabled = useStoryExplorationStore((state) => state.isExploreModeEnabled);

  const mapRef = useRef<MapRef>(null);
  const initialViewState = {
    latitude: 52.5142,
    longitude: 13.39,
    zoom: 13,
  };
  const [viewState, setViewState] = useState(initialViewState);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOURS_MAP);
    }

    resizeMap();
  });

  useEffect(() => {
    if (currentCity) {
      setViewState({
        zoom: 13,
        latitude: currentCity?.location?.latitude,
        longitude: currentCity?.location?.longitude,
      });
    }
  }, [currentCity]);

  useEffect(() => {
    if (wasKeyboardOpen && !isKeyboardOpen) resizeMap();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isKeyboardOpen]
  );

  useDebouncedEffect(() => {
    if (tours?.length && mapRef) {
      tours.forEach((tour) => {
        mapRef?.current?.on('click', tour.id, () => {
          setActiveTourId(tour.id);
          setIsSliderOpened(true);
        });
      });
    }

  }, [mapRef, tours], 10);

  // fit all tour stops to the map
  useDebouncedEffect(() => {
    if (isVisible && tours?.length) {
      const activeTour = find(tours, ['id', activeTourId]);

      if (activeTour?.routeGeoJson) {
        const tourIndex = findIndex(tours, ['id', activeTourId]);
        setActiveTourIndex(tourIndex);

        const tourStopCoordinates: any = activeTour?.tourStops?.map((tourStop) =>
          ([tourStop?.location?.longitude, tourStop?.location?.latitude])
        );

        const features = lineString(tourStopCoordinates);
        const [minLng, minLat, maxLng, maxLat] = bbox(features);

        mapRef?.current?.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
          padding: {
            top: 150,
            right: 40,
            bottom: isSliderOpened ? 345 : 135,
            left: 40,
          },
          duration: 1000
        });
      }
    }
  }, [tours, activeTourId, isSliderOpened, mapRef, isVisible], 10);

  const resizeMap = () => {
    mapRef?.current?.resize();
  };

  const handleTouchStart = (event: any) => {
    setSliderTouchStartY(event.touches[0].clientY);
  };

  const handleTouchMove = (event: any) => {
    const deltaY = event.touches[0].clientY - sliderTouchStartY;
    setSliderTranslateY(deltaY);
  };

  const handleTouchEnd = (event: any) => {
    // update slider position only if the user pulls it more than 50px
    if (!inRange(sliderTranslateY, 50, -50)) {
      setIsSliderOpened(sliderTouchStartY > event.changedTouches[0].clientY);
    }
    setSliderTranslateY(0);
    setSliderTouchStartY(0);
  };

  const modalStyle = {
    transform: `translateY(${isSliderOpened ? sliderTranslateY : 225 + sliderTranslateY}px)`,
  };

  return (
    <AppLayout>
      <AppHeader isAbsolutelyPositioned={true}/>

      <IonContent>
        <div className="relative h-full w-full overflow-hidden">
          <FloatingBackButton style={{
            left: '6px',
            marginTop: `calc(var(--ion-safe-area-top, 12px) + ${isExploreModeEnabled ? 115 : 100}px)`
          }}/>

          {!!currentCity?.id && <ToursLanguageSwitcher
            toursNumberForLocales={toursNumberForLocales}
            isVisible={isVisible}
            wrapperClassName="absolute z-10 left-1/2 -translate-x-1/2"
            wrapperStyle={{ top: `calc(var(--ion-safe-area-top, 12px) + ${isExploreModeEnabled ? 80 : 65}px)` }}
          />}

          <Map
            ref={mapRef}
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            attributionControl={false}
            reuseMaps={true}
            dragRotate={false}
            style={{
              height: '100%',
              width: '100%',
            }}
            mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
            onLoad={(e) => {
              setMapLanguage(e, locale);
            }}
          >
            {isVisible && <GeolocateControl
              mapRef={mapRef}
              position={{
                bottom: `${isSliderOpened ? 325 - sliderTranslateY : 100 - sliderTranslateY}px`,
                right: '16px'
              }}
              updateCityByUserLocationOnLocateButtonClicking={true}
            />}

            {tours?.map((tour) =>
              <TourRoute
                key={tour.id}
                tour={tour}
                isActive={activeTourId === tour.id && isSliderOpened}
                isStartStopMarkersVisible={true}
              />
            )}
          </Map>

          <div
            className="absolute left-0 right-0 z-50 mb-2 mx-auto w-full bottom-0 max-w-3xl"
            style={modalStyle}
          >
            <Slider
              sliderProps={{
                virtual: true,
                centeredSlides: true,
                // Default config (when window width is < 320px)
                slidesPerView: 1,
                spaceBetween: 6,

                // Responsive breakpoints
                breakpoints: {
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1.2,
                  },
                  // when window width is >= 480px
                  480: {
                    slidesPerView: 1.7,
                  },
                  // when window width is >= 640px
                  640: {
                    slidesPerView: 2.2,
                  },
                },

                // Round lengths of slides to whole numbers
                //  since there's text on most images
                roundLengths: true,

                onSlideChange: () => {
                  if (mixpanelEnabled) {
                    mixpanel.track(MixpanelEvents.TOURS_MAP_SLIDER_SCROLLED);
                  }
                },
              }}
              slideToIndex={activeTourIndex}
            >
              {tours.map((tour) => <TourCard
                key={`tour-card-${tour.id}`}
                tour={tour}
                setActiveTourId={setActiveTourId}
                handleTouchStart={handleTouchStart}
                handleTouchMove={handleTouchMove}
                handleTouchEnd={handleTouchEnd}
              />)}
            </Slider>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default ToursMapPage;
