import { useEffect, useState } from 'react';

import { useDefaultCityImageQuery } from '../graphql/dato/__generated__/dato-graphql.generated';

const useDefaultCityImage = () => {
  const [defaultCityImageUrl, setDefaultCityImageUrl] = useState<string>();

  const [applicationResult] = useDefaultCityImageQuery();

  useEffect(() => {
    if (applicationResult?.data?.application?.defaultCityImage?.responsiveImage?.src) {
      setDefaultCityImageUrl(applicationResult?.data?.application?.defaultCityImage?.responsiveImage?.src);
    }
  }, [applicationResult?.data]);

  return {
    defaultCityImageUrl
  }
};

export default useDefaultCityImage;
